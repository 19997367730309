import { useMemo } from 'react';
import Image from "next/legacy/image";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { DEFAULT_BLUR_IMAGE_DATA_URL } from 'constants/blurImage';

const HEALTH_FUND_IMAGES = Object.freeze([
  {
    alt: 'Bupa',
    width: 74,
    height: 68,
    country: 'AU',
    src: '/images/health-funds/au/bupa.png',
    href: '/eye-health/bupa-glasses'
  },
  {
    alt: 'Medibank',
    width: 118,
    height: 23,
    country: 'AU',
    src: '/images/health-funds/au/medi-bank.png'
  },
  {
    alt: 'HBF',
    width: 99,
    height: 35,
    country: 'AU',
    src: '/images/health-funds/au/hbf.png'
  },
  {
    alt: 'HCF',
    width: 113,
    height: 43,
    country: 'AU',
    src: '/images/health-funds/au/hcf.png',
    href: '/eye-health/hcf-glasses'
  },
  {
    alt: 'AHM',
    width: 93,
    height: 58,
    country: 'AU',
    src: '/images/health-funds/au/ahm.png'
  },
  {
    alt: 'Health Partners',
    width: 103,
    height: 86,
    country: 'AU',
    src: '/images/health-funds/au/health-partners.png'
  },
  {
    alt: 'Manulife',
    width: 136,
    height: 26,
    country: 'CA',
    src: '/images/health-funds/ca/manulife.png'
  },
  {
    alt: 'Sun Life',
    width: 130,
    height: 33,
    country: 'CA',
    src: '/images/health-funds/ca/sun-life.png'
  },
  {
    alt: 'GSC',
    width: 109,
    height: 48,
    country: 'CA',
    src: '/images/health-funds/ca/gsc.png'
  },
  {
    alt: 'Pacific Blue Cross',
    width: 130,
    height: 20,
    country: 'CA',
    src: '/images/health-funds/ca/pacific-blue-cross.png'
  },
  {
    alt: 'Claim Secure',
    width: 140,
    height: 32,
    country: 'CA',
    src: '/images/health-funds/ca/claim-secure.png'
  },
  {
    alt: 'Canada Life',
    width: 122,
    height: 46,
    country: 'CA',
    src: '/images/health-funds/ca/canada-life.png'
  }
]);

const MESSAGE: Record<string, { title: string; buttonText: string }> = {
  default: {
    title: 'We accept all major health funds',
    buttonText: 'More details about the health funds'
  },
  ca: {
    title: 'We direct bill major health insurances',
    buttonText: 'More details about health insurance'
  }
};

const HomepageAcceptedHealthFunds = () => {
  const router = useRouter();

  const healthFundImages = useMemo(
    () =>
      HEALTH_FUND_IMAGES.filter(
        (image) => image.country.toLowerCase() === router.locale
      ),
    [router.locale]
  );

  const healthFundMessage = useMemo(
    () => MESSAGE[router.locale as string] || MESSAGE.default,
    [router.locale]
  );

  return healthFundImages.length ? (
    <section className="bg-brand-grey250 py-[40px] px-4 sm:py-[88px]">
      <div className="mx-auto flex max-w-[1224px] flex-col lg:px-4">
        <span className="mx-auto mb-[32px] max-w-[230px] text-center text-[24px] font-semibold text-brand-black100 sm:mb-[40px] sm:max-w-none md:text-[36px]">
          {healthFundMessage.title}
        </span>
        <div className="grid grid-cols-3 gap-[10px] md:gap-[24px] lg:grid-cols-6">
          {healthFundImages.map((healthFundImage) => (
            healthFundImage.href ? (
              <Link key={healthFundImage.country + '_' + healthFundImage.alt} href={healthFundImage.href}>
                <div
                  className="flex h-[100px] items-center justify-center rounded-[8px] bg-white px-[18px] transition-shadow duration-500 hover:shadow-card sm:px-0 md:h-[140px]">
                  <Image
                    placeholder="blur"
                    blurDataURL={DEFAULT_BLUR_IMAGE_DATA_URL}
                    alt={healthFundImage.alt}
                    src={healthFundImage.src}
                    width={healthFundImage.width}
                    height={healthFundImage.height}
                    quality={80}
                  />
                </div>
              </Link>
            ) : (
              <div
                key={healthFundImage.country + '_' + healthFundImage.alt}
                className="flex h-[100px] items-center justify-center rounded-[8px] bg-white px-[18px] transition-shadow duration-500 hover:shadow-card sm:px-0 md:h-[140px]">
                <Image
                  placeholder="blur"
                  blurDataURL={DEFAULT_BLUR_IMAGE_DATA_URL}
                  alt={healthFundImage.alt}
                  src={healthFundImage.src}
                  width={healthFundImage.width}
                  height={healthFundImage.height}
                  quality={80}
                />
              </div>
            )
          ))}
        </div>
        <div className="mt-[34px] flex items-center justify-center">
          <Link
            prefetch={false}
            href="/eye-health/health-funds"
            className="block min-h-[48px] w-full items-center justify-center rounded-[6px] border border-brand-grey400 px-[30px] text-center text-[13px] font-medium leading-[44px] text-brand-black100 transition-colors duration-200 hover:text-brand-orange focus:text-brand-grey450 xsm:text-[16px] sm:w-auto">
            {healthFundMessage.buttonText}
          </Link>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default HomepageAcceptedHealthFunds;
